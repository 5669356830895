// https://www.crockford.com/base32.html
// https://github.com/LinusU/base32-encode/blob/master/index.js

const BASE32_ALPHABET = "0123456789ABCDEFGHJKMNPQRSTVWXYZ"
// const alphabet = '0123456789abcdefghjkmnpqrstvwxyz'

export function base32Encode(
  buffer: Buffer | ArrayBuffer,
  characterOutputLength: number = -1
): string {
  // @ts-ignore
  let length = buffer.length || buffer.byteLength
  let view = new Uint8Array(buffer)

  let bits = 0
  let value = 0
  let output = ""

  for (let i = 0; i < length; i++) {
    value = (value << 8) | view[i]
    bits += 8

    while (bits >= 5) {
      output += BASE32_ALPHABET[(value >>> (bits - 5)) & 31]
      bits -= 5

      if (characterOutputLength >= 0) {
        if (output.length >= characterOutputLength) {
          return output
        }
      }
    }
  }

  if (bits > 0) {
    output += BASE32_ALPHABET[(value << (5 - bits)) & 31]
  }

  return output
}
