import { config } from "@/config"
import { ViidooConnectionState } from "@/types"
import { Disposable, Emitter, Logger } from "lib"
import { IndexeddbPersistence } from "y-indexeddb"
import * as Y from "yjs"
import { state } from "../state"
import { SimpleWebRTC } from "./simple-webrtc"
import { YjsRealtimeSync } from "./yjs-realtime-sync"

const log = Logger("realtime")

export class ViidooRoomManager
  extends Emitter<{
    ready(): void
    connectionState(value: ViidooConnectionState): void
    error(message: string): void
  }>
  implements Disposable
{
  doc: Y.Doc
  name: string
  secret: string
  connectionState: ViidooConnectionState

  constructor() {
    super()
    log("ViidooRoomManager")
  }

  async openRoom(name: string, secret: string | undefined = undefined) {
    log("openRoom", name, secret)

    await this.cleanup()

    if (secret == null) {
      secret = localStorage.getItem(`room-secret-${name}`) || "000000"
    }
    this.secret = secret
    state.secret = secret
    localStorage.setItem(`room-secret-${name}`, secret)

    log.info("Open room", name, secret)

    this.doc = new Y.Doc()
    this.name = name
    this.useLocalPersistence()
    this.useWebRTC()
    this.emit("ready") // but not connected!
  }

  async cleanup() {
    state.error = ""
    this.setConnectionState("none")

    await this?.provider?.cleanup()
    await this?.webrtc?.cleanup()
    this.provider = undefined
    this.webrtc = undefined
    this.doc = undefined
    this.name = undefined
  }

  // Persistence

  indexeddbProvider?: IndexeddbPersistence

  private useLocalPersistence() {
    if (!this.indexeddbProvider) {
      this.indexeddbProvider = new IndexeddbPersistence(this.name, this.doc)
      this.indexeddbProvider.whenSynced.then(() => {
        log.debug("loaded data from indexed db")
      })
    }
  }

  // Connection

  private setConnectionState(connection: ViidooConnectionState) {
    log(`Change connection state from ${state.connection} to ${connection}`)
    this.connectionState = connection
    state.connection = connection
    this.emit("connectionState", connection)
  }

  webrtc: SimpleWebRTC
  provider: YjsRealtimeSync

  private useWebRTC() {
    log.info("useWebRTC")

    this.setConnectionState("pending")

    this.webrtc = new SimpleWebRTC({
      room: this.name,
      secret: this.secret,
      peerSettings: config.webrtc.ice,
      // stream: mediaManager.deviceStream,
    })

    // mediaManager.on("updateMedia", (mediaManager) => {
    //   webrtc.setStream(mediaManager.deviceStream)
    // })

    this.webrtc.on("error", (msg) => {
      log.info("error", msg)
      if (msg === "auth") {
        this.setConnectionState("auth")
      }
      this.emit("error", msg)
    })

    this.webrtc.on("authenticated", ({ role }) => {
      if (this.connectionState === "moderator") return
      this.setConnectionState(role)
    })

    this.webrtc.on("connect", () => {
      if (this.connectionState === "moderator") return
      this.setConnectionState("participant")
    })

    // this.webrtc.on("close", ({ peer }) => {
    //   this.setConnectionState("none")
    // })

    // mediaManager.start()

    state.webrtc = this.webrtc.reactive

    this.webrtc.connect()

    this.provider = new YjsRealtimeSync(this.webrtc, this.doc)

    // setupRoomSync()
  }
}
