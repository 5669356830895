import { config, DEBUG } from "@/config"
import {
  Logger,
  LoggerBrowserHandler,
  LogHandler,
  LogLevel,
  LogMessage,
  renderMessages,
} from "lib"
import { emitNotification } from "twindy-headless"
import { LoggerSentryHandler, setupSentry } from "./sentry"

const shouldUseSentry = !DEBUG && config.sentry.active && config.sentry.dsn

if (shouldUseSentry) {
  setupSentry() // todo: implement lazy
}

export function LoggerNotificationHandler(
  level: LogLevel = LogLevel.debug
): LogHandler {
  return (msg: LogMessage) => {
    if (msg.level < level) return
    let name = msg.name || ""
    switch (msg.level) {
      case LogLevel.error:
        let message = renderMessages(msg.messages, {
          trace: false,
        })
        emitNotification({
          title: "Error in " + name,
          message,
          timeout: 10000,
        })
        break
    }
  }
}

// Logger.setLogLevel(LogLevel.info)
Logger.setHandlers([
  LoggerBrowserHandler(),
  LoggerNotificationHandler(),
  shouldUseSentry && LoggerSentryHandler(),
])

// Logger.setPrefix("app")

export { Logger }
