<style lang="stylus">
@import "inter-ui/inter.css"
@require "./app.styl"
</style>

<template>
  <div v-if="!config.browser.supported">This browser is not supported!</div>
  <router-view />
  <div v-if="preview" class="app-preview-banner">PREVIEW</div>
</template>

<script lang="ts">
import { DEVELOPMENT, config } from "@/config"
import { defineComponent, onMounted } from "vue"
import { state } from "../state"

export default defineComponent({
  components: {
    // TwNotification,
  },

  setup() {
    // setInterval(() => {
    //   notification({
    //     title: "Test " + UUID(),
    //   })
    // }, 2000)

    onMounted(() => {
      // window.addEventListener("keydown", (ev: KeyboardEvent) => {
      //   let element: any = ev.target
      //   if (!["INPUT", "TEXTAREA"].includes(element?.tagName)) {
      //     if (ev.key === "/") {
      //       ev.preventDefault()
      //       ev.stopPropagation()
      //     }
      //   }
      // })
    })
    return {
      state,
      config,
      preview: !DEVELOPMENT,
    }
  },
})
</script>
