import { Dictionary, messages } from "lib"
import { Logger } from "lib"
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { state } from "./state"

const log = Logger("router")

// const SandboxLayout = () =>
//   import( "./layout/test-layout.vue")

function getDynamicRoutes(
  modules: any,
  pathPrefix: string = "/"
): RouteRecordRaw[] {
  // let modules = import.meta.glob(pattern)
  return Object.entries(modules).map(([path, component]) => {
    const name = path.split("/").pop()?.slice(0, -4)
    return {
      path: pathPrefix + name,
      name: name,
      component,
    }
  })
}

export const contents = ["help", "privacy", "imprint", "fix"]

let testRoutes = getDynamicRoutes(import.meta.glob("/**/test-*.vue"), "/test/")
// console.log("tests", tests)

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Viidoo Chat - Secure, Real time, Collaborative",
      metaTags: [
        {
          name: "description",
          content: "Secure video calls and real time collaboration.",
        },
        {
          property: "og:description",
          content: "Secure video calls and real time collaboration.",
        },
      ],
    },
    component: () => import("./views/app-home.vue"),
  },
  {
    path: "/room/:room",
    name: "room",
    meta: {
      title: "Viidoo Room",
    },
    props: true,
    component: () => import("./views/room/app-room.vue"),
  },

  ...contents.map((name) => ({
    path: "/" + name,
    name,
    props: { name },
    // meta: {
    //   title: name,
    // },
    component: () => import("./views/app-content.vue"),
  })),

  {
    path: "/test/",
    component: () => import("./views/app-test.vue"),
    props: {
      routes: Object.values(testRoutes),
    },
  },

  ...testRoutes,

  {
    path: "/admin/",
    component: () => import("./views/admin/app-admin.vue"),
  },

  // {
  //   path: "/sandbox/layout",
  //   component: SandboxLayout,
  // },

  // {
  //   path: "/test/layout",
  //   component: TestLayout,
  // },

  // ...views.map((name) => ({
  //   path: "/editor",
  //   name: "editor",
  //   component: () =>
  //     import( */ `./views/${name}.vue`),
  // })),

  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("./views/app-home.vue"),
  },
]

export const router = createRouter({
  routes,

  // https://next.router.vuejs.org/guide/essentials/history-mode.html#html5-mode
  history: createWebHistory(),
})

// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  log.info("before route to=", to?.fullPath, ", from=", from?.fullPath)

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched
  //   .slice()
  //   .reverse()
  //   .find((r) => r.meta && r.meta.metaTags)

  if (to.hash) {
    messages.emit("roomSecret", to.hash.slice(1))
    log("set secret", to.hash)
    state.secret = to.hash.slice(1)
  }

  // If a route with a title was found, set the document (page) title to that value.
  // @ts-ignore
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el?.parentNode?.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    // @ts-ignore
    .map((tagDef: Dictionary): Node => {
      const tag = document.createElement("meta")

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "")

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag: Node) => document.head.appendChild(tag))

  next()
})
