import { config } from "@/config"
import Bowser from "bowser"
import { Logger, SerialQueue, sleep } from "lib"
import type { Device } from "mediasoup-client"
import * as mediasoup from "mediasoup-client"
import {
  DtlsParameters,
  IceCandidate,
  IceParameters,
  MediaKind,
  RtpCapabilities,
  RtpParameters,
} from "mediasoup-client/lib/types"
import { Connection } from "./socket-connection"

const log = Logger("sfu-client")

// window.localStorage.setItem(
//   "debug",
//   "mediasoup-client:*" // mediasoup-client:ERROR*"
// )

declare module "./socket-connection" {
  interface SocketEvents {
    sfuGetRouterRtpCapabilities(): Promise<RtpCapabilities>

    sfuCreateProducerTransport(): Promise<{
      id: string
      iceParameters: IceParameters
      iceCandidates: IceCandidate[]
      dtlsParameters: DtlsParameters
      error?: string
    }>

    sfuConnectProducerTransport(msg: {
      id: string
      dtlsParameters: any
    }): Promise<void>

    sfuProduce(msg: {
      id: string
      kind: MediaKind
      rtpParameters: any
      appData?: any
    }): Promise<{
      producerId: string
      appData?: any
    }>

    sfuCreateConsumerTransport(): Promise<{
      id: string
      iceParameters: IceParameters
      iceCandidates: IceCandidate[]
      dtlsParameters: DtlsParameters
    }>

    sfuConnectConsumerTransport(msg: {
      id: string
      dtlsParameters: DtlsParameters
    }): Promise<void>

    sfuConsume(msg: {
      rtpCapabilities: RtpCapabilities
      consumerTransportId: string
      producerTransportId: string
    }): Promise<{
      consumerTransportId: string
      producerId: string
      id: string
      kind: MediaKind
      rtpParameters: RtpParameters
      type: string // ConsumerType
      producerPaused: boolean
      appData?: any
    }>

    sfuConsumerPause(msg: { id: string }): Promise<void>
    sfuConsumerResume(msg: { id: string }): Promise<void>

    sfuProducerPause(msg: { id: string }): Promise<void>
    sfuProducerResume(msg: { id: string }): Promise<void>
    sfuProducerClose(msg: { id: string }): Promise<void>
  }
}

export class SfuClient extends SerialQueue {
  uuid: string
  conn: Connection
  device: Device

  constructor(conn: Connection) {
    super()

    log.info("Init SfuClient")
    this.conn = conn
  }

  // socketRequest(type: string, data = {}): Promise<any> {
  //   return new Promise((resolve) => {
  //     log.debug("SEND", type, data)
  //     log.assert(this.socket, "socket required")
  //     this.socket.emit(type, data, (info: any) => {
  //       if (info?.error) {
  //         log.error("RECV", type, info.error)
  //       } else {
  //         log.debug("RECV", type, info)
  //       }
  //       resolve(info)
  //     })
  //   })
  // }

  async getDevice(routerRtpCapabilities: any) {
    log("getDevice with routerRtpCapabilities", routerRtpCapabilities)
    let error: any[]
    if (!this.device) {
      // Try three times
      for (let i = 0; i < 3; i++) {
        error = undefined
        try {
          // https://mediasoup.org/documentation/v3/mediasoup-client/api/#mediasoupClient-detectDevice
          let handlerName = mediasoup.detectDevice()
          if (!handlerName) {
            try {
              const browserInfo = Bowser.parse(window.navigator.userAgent)
              const [major, minor] = (browserInfo?.os?.version || "0.0").split(
                "."
              )
              if (
                browserInfo.os.name.toLocaleLowerCase() === "ios" &&
                +major >= 14 &&
                +minor >= 3
              ) {
                handlerName = "Safari12"
              }
            } catch (err) {}
          }
          log.debug(
            `loadDevice try #%{i} using handler '${handlerName}'`,
            mediasoup
          )
          this.device = new mediasoup.Device({ handlerName })
          if (this.device) break
        } catch (error) {
          if (error.name === "UnsupportedError") {
            error = ["This browser is not supported by MediaSoup"]
          } else {
            error = ["loadDevice error", error]
          }
          log.warn(...error)
        }
        await sleep(1000)
      }
      if (error) {
        log.error(...error)
      }
      log.debug("device", this.device)
      if (this.device) {
        await this.device.load({ routerRtpCapabilities })
        log.debug("loaded device", this.device)
      }
    }
    return this.device
  }

  async setup() {
    await this.enqueue(async () => {
      log.debug("setup")
      const capabilities = await this.conn.emit("sfuGetRouterRtpCapabilities")
      log.debug("capabilities", capabilities)

      // iOS video rotation fix
      // https://mediasoup.org/documentation/v3/tricks/#rtp-capabilities-filtering
      capabilities.headerExtensions = capabilities.headerExtensions.filter(
        (ext) => ext.uri !== "urn:3gpp:video-orientation"
      )

      await this.getDevice(capabilities)
      log.debug("setup done")
    })
  }

  async createProducer(
    kind: MediaKind,
    track: MediaStreamTrack,
    appData: any = {}
  ): Promise<string> {
    return this.enqueue(async () => {
      // log.debug("rtc", this.device.rtpCapabilities)
      const data = await this.conn.emit("sfuCreateProducerTransport")
      if (data.error) {
        log.error("createProducer error:", data.error)
        throw data.error
      }

      const transport = this.device.createSendTransport(data)
      if (!this.device) throw new Error("device missing")

      const transportId: string = transport.id
      if (!transportId) throw new Error("transport.id missing")

      transport.on(
        "connect",
        async (
          { dtlsParameters },
          callback: (value: any) => any,
          errback: (value: any) => any
        ) => {
          log.debug("p.t.connect", dtlsParameters)
          this.conn
            .emit("sfuConnectProducerTransport", {
              id: transportId,
              dtlsParameters,
            })
            .then(callback)
            .catch(errback)
        }
      )

      transport.on(
        "produce",
        async (
          { kind, rtpParameters },
          callback: (value: any) => any,
          errback: (value: any) => any
        ) => {
          log.debug("p.t.produce", kind)
          try {
            const result = await this.conn.emit("sfuProduce", {
              id: transportId,
              kind,
              rtpParameters,
            })
            log.debug("produce result", result, transportId)
            callback({ id: result.producerId })
          } catch (err) {
            log.error("p.t.produce", err)
            errback(err)
          }
        }
      )

      transport.on("connectionstatechange", (state: string) => {
        log.debug("p.t.connectionstatechange", state)
        switch (state) {
          case "connecting":
            break
          case "connected":
            // document.querySelector('#local_video').srcObject = stream
            break
          case "failed":
            log.warn("t.connectionstatechange failed")
            transport.close()
            break
          default:
            break
        }
      })

      try {
        const params = {
          track,
          encodings: undefined,
          codecOptions: undefined,
          appData,
        }

        if (kind === "audio") {
          params.codecOptions = {
            opusStereo: 1,
            opusDtx: 1,
          }
        }

        if (kind === "video") {
          params.codecOptions = {
            videoGoogleStartBitrate: 1000,
          }

          if (config.sfu.simulcast) {
            params.encodings = config.sfu.encodings

            if (config.sfu.supportVP9) {
              // If VP9 is the only available video codec then use SVC.
              const firstVideoCodec =
                this.device?.rtpCapabilities?.codecs?.find(
                  (c) => c.kind === "video"
                )
              log("firstVideoCodec", this.device?.rtpCapabilities?.codecs)

              if (firstVideoCodec?.mimeType.toLowerCase() === "video/vp9") {
                params.encodings = config.sfu.encodingsVP9
                //  [{ scalabilityMode: "S3T3", dtx: true }] // desktop sharing
              }
            }

            // @ts-ignore
            params.codec = this.device?.rtpCapabilities?.codecs?.find(
              (codec) => codec.mimeType.toLowerCase() === "video/h264"
            )
          }
        }

        log.debug("produce params =", kind, params)
        await transport.produce(params)
      } catch (err) {
        log.error("produce", err)
        throw err
      }

      return transportId
    })
  }

  async createConsumer(
    producerTransportId: string,
    streamCallback: {
      (arg0: {
        stream: MediaStream
        state?: "connected"
        consumerTransportId?: any
        appData?: any
      }): void
    }
  ): Promise<void> {
    return this.enqueue(async () => {
      log.debug("createConsumer", producerTransportId)
      const data = await this.conn.emit("sfuCreateConsumerTransport")
      // if (data.error) throw new Error(data.error)
      if (!this.device) throw new Error("device missing")

      const consumerTransportId = data.id
      const IDD = consumerTransportId + ""
      if (!consumerTransportId) throw new Error("consumerTransportId missing")

      const transport = this.device.createRecvTransport(data)

      transport.on(
        "connect",
        (
          { dtlsParameters },
          callback: (value: any) => any,
          errback: (value: any) => any
        ) => {
          log.debug("s.t.connect", dtlsParameters)
          this.conn
            .emit("sfuConnectConsumerTransport", {
              id: consumerTransportId,
              dtlsParameters,
            })
            .then(callback)
            .catch(errback)
        }
      )

      transport.on("connectionstatechange", async (state: string) => {
        log.debug("s.t.connectionstatechange", state)
        switch (state) {
          case "connecting":
            break
          case "connected":
            log.debug("s.t.stream", stream)
            let s = await stream
            // log.debug("s.t.stream2", s)
            if (streamCallback)
              streamCallback({
                stream: s,
                state,
                consumerTransportId,
                appData: transport.appData,
              })
            // await this.conn.emit("sfuResume", {
            //   id: IDD,
            // })
            break
          case "failed":
            transport.close()
            break
          default:
            break
        }
      })

      // This wrapper is absolutely neccessary!
      // I have spent days on it! It seems that we need to wrap the stream
      // in a Promise so it can be resolved  when connected. Doint this in
      // a wrapper (value: any)=>any seeems to be the easiest way.
      const consume = async (transport: any) => {
        const { rtpCapabilities } = this.device
        const dataConsume = await this.conn.emit("sfuConsume", {
          rtpCapabilities,
          consumerTransportId,
          producerTransportId,
        })
        const { producerId, id, kind, rtpParameters } = dataConsume
        const consumer = await transport.consume({
          id,
          producerId,
          kind,
          rtpParameters,
        })
        log("appData", consumer, consumer.appData)
        const workStream = new MediaStream()
        workStream.addTrack(consumer.track)
        return workStream
      }

      const stream = consume(transport)
    })
  }
}
