// import { trackException, trackSilentException } from "../bugs"

import { Logger } from "lib"
import { MediaInfo } from "../types"

const log = Logger("usermedia-stream")

export async function getDevices(): Promise<MediaDeviceInfo[]> {
  try {
    return navigator.mediaDevices.enumerateDevices()
  } catch (err) {
    log.error(err)
  }
  return []
}

// export let bandwidthVideoConstraints: MediaTrackConstraints = {
//   // video: {
//   //   width: { ideal: 320 },
//   //   height: { ideal: 240 },
//   // },
//   // width: { ideal: 320 },
//   // height: { ideal: 240 },
// }

function __getUserMedia(
  constraints: MediaStreamConstraints
): Promise<MediaStream> {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    return navigator.mediaDevices.getUserMedia(constraints)
  }
  const _getUserMedia =
    navigator.getUserMedia ||
    // @ts-ignore
    navigator.webkitGetUserMedia ||
    // @ts-ignore
    navigator.mozGetUserMedia
  return new Promise((resolve, reject) => {
    if (!_getUserMedia) {
      reject(
        new Error(
          "Video and audio cannot be accessed. Please try again with another browser or check your browser's settings."
        )
      )
    } else {
      _getUserMedia.call(navigator, constraints, resolve, reject)
    }
  })
}

export async function getUserMedia(
  constraints: MediaStreamConstraints
): Promise<MediaInfo> {
  try {
    // Solution via https://stackoverflow.com/a/47958949/140927
    // Only available for HTTPS! See https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Security
    log.debug("getUserMedia constraints", constraints)
    let stream = await __getUserMedia(constraints)
    return { stream }
  } catch (error) {
    const name = error?.name || error?.toString()
    if (name === "NotAllowedError") {
      return {
        error,
        message:
          "You denied access to your camera and microphone. Please check your setup.",
      }
    } else if (name === "NotFoundError") {
      return {
        error,
        message: "No camera or microphone has been found!",
      }
    }
    return {
      error,
      message: error?.message || error?.name || String(error),
    }
  }
}

export async function getDisplayMedia(
  constraints: MediaStreamConstraints
): Promise<MediaInfo> {
  try {
    // @ts-ignore
    if (!navigator?.mediaDevices?.getDisplayMedia) {
      return {
        error: "Accessing the desktop is not available.",
      }
    }
    // Solution via https://stackoverflow.com/a/47958949/140927
    // Only available for HTTPS! See https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Security
    log.debug("getDisplayMedia constraints", constraints)
    // @ts-ignore
    let stream = await navigator.mediaDevices.getDisplayMedia(constraints)
    return { stream }
  } catch (err) {
    const name = err?.name || err?.toString()
    if (name === "NotAllowedError") {
      return {
        error:
          "You denied access to your camera and microphone. Please check your setup.",
      }
    } else if (name === "NotFoundError") {
      return {
        error: "No camera or microphone has been found!",
      }
    }
    log.error(err)
    return {
      error: err?.message || err?.name || err.toString(),
    }
  }
}

export function setAudioTracks(
  stream: MediaStream,
  audioTracks: MediaStreamTrack[]
): MediaStream {
  Array.from(stream.getAudioTracks()).forEach((t) => stream.removeTrack(t))
  audioTracks.forEach((t) => {
    try {
      stream.addTrack(t)
    } catch (err) {
      if (err?.message !== "Track has already been added to that stream.") {
        log.error(err)
      }
    }
  })
  return stream
}
