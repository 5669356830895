import { LogHandler, LogLevel, LogMessage } from "../common/log"
import { Dictionary } from "../common/types"

const colors = [
  "#0000CC",
  "#0000FF",
  "#0033CC",
  "#0033FF",
  "#0066CC",
  "#0066FF",
  "#0099CC",
  "#0099FF",
  "#00CC00",
  "#00CC33",
  "#00CC66",
  "#00CC99",
  "#00CCCC",
  "#00CCFF",
  "#3300CC",
  "#3300FF",
  "#3333CC",
  "#3333FF",
  "#3366CC",
  "#3366FF",
  "#3399CC",
  "#3399FF",
  "#33CC00",
  "#33CC33",
  "#33CC66",
  "#33CC99",
  "#33CCCC",
  "#33CCFF",
  "#6600CC",
  "#6600FF",
  "#6633CC",
  "#6633FF",
  "#66CC00",
  "#66CC33",
  "#9900CC",
  "#9900FF",
  "#9933CC",
  "#9933FF",
  "#99CC00",
  "#99CC33",
  "#CC0000",
  "#CC0033",
  "#CC0066",
  "#CC0099",
  "#CC00CC",
  "#CC00FF",
  "#CC3300",
  "#CC3333",
  "#CC3366",
  "#CC3399",
  "#CC33CC",
  "#CC33FF",
  "#CC6600",
  "#CC6633",
  "#CC9900",
  "#CC9933",
  "#CCCC00",
  "#CCCC33",
  "#FF0000",
  "#FF0033",
  "#FF0066",
  "#FF0099",
  "#FF00CC",
  "#FF00FF",
  "#FF3300",
  "#FF3333",
  "#FF3366",
  "#FF3399",
  "#FF33CC",
  "#FF33FF",
  "#FF6600",
  "#FF6633",
  "#FF9900",
  "#FF9933",
  "#FFCC00",
  "#FFCC33",
]

function supportsColors(): boolean {
  // NB: In an Electron preload script, document will be defined but not fully
  // initialized. Since we know we're in Chrome, we'll just detect this case
  // explicitly
  if (
    typeof window !== "undefined" &&
    window.process &&
    // @ts-ignore
    (window.process.type === "renderer" || window.process.__nwjs)
  ) {
    return true
  }

  // Internet Explorer and Edge do not support colors.
  if (
    typeof navigator !== "undefined" &&
    navigator.userAgent &&
    navigator.userAgent.toLowerCase().match(/(edge|trident)\/(\d+)/)
  ) {
    return false
  }

  // Is webkit? http://stackoverflow.com/a/16459606/376773
  // document is undefined in react-native: https://github.com/facebook/react-native/pull/1632
  return (
    (typeof document !== "undefined" &&
      document.documentElement &&
      document.documentElement.style &&
      // @ts-ignore
      document.documentElement.style.WebkitAppearance) ||
    // Is firebug? http://stackoverflow.com/a/398120/376773
    (typeof window !== "undefined" &&
      window.console &&
      // @ts-ignore
      (window.console.firebug ||
        // @ts-ignore
        (window.console.exception && window.console.table))) ||
    // Is firefox >= v31?
    // https://developer.mozilla.org/en-US/docs/Tools/Web_Console#Styling_messages
    (typeof navigator !== "undefined" &&
      navigator.userAgent &&
      navigator.userAgent.toLowerCase().match(/firefox\/(\d+)/) &&
      parseInt(RegExp.$1, 10) >= 31) ||
    // Double check webkit in userAgent just in case we are in a worker
    (typeof navigator !== "undefined" &&
      navigator.userAgent &&
      navigator.userAgent.toLowerCase().match(/applewebkit\/(\d+)/))
  )
}

function selectColor(namespace: string) {
  let hash = 0
  for (let i = 0; i < namespace.length; i++) {
    hash = (hash << 5) - hash + namespace.charCodeAt(i)
    hash |= 0 // Convert to 32bit integer
  }
  return colors[Math.abs(hash) % colors.length]
}

let namespaces: Dictionary = {}

function now(): number {
  if (typeof performance !== "undefined") {
    return performance.now()
  }
  return new Date().getTime()
}

let time = now()

const useColors = supportsColors()

export function LoggerBrowserHandler(
  level: LogLevel = LogLevel.debug
): LogHandler {
  return (msg: LogMessage) => {
    if (msg.level < level) return

    const timeNow = now()
    let name = msg.name || ""
    let ninfo = namespaces[name || ""]
    if (ninfo == null) {
      ninfo = {
        color: selectColor(name),
        // time: timeNow
      }
      namespaces[name] = ninfo
    }
    let diff: number | string = timeNow - time
    time = timeNow
    if (diff > 999) {
      diff = diff.toFixed(1) + "s"
    } else {
      diff = diff.toFixed(2) + "ms"
    }
    let args: string[]
    if (useColors) {
      args = [`%c[${name}]`]
      args.push(`color:${ninfo.color}`)
      args.push(...msg.messages)
    } else {
      args = [name, ...msg.messages]
    }
    args.push(`+${diff}`)
    switch (msg.level) {
      case LogLevel.info:
        args[0] = `I|*   ` + args[0]
        console.log(...args)
        break
      case LogLevel.warn:
        args[0] = `W|**  ` + args[0]
        console.warn(...args)
        break
      case LogLevel.error:
        args[0] = `E|*** ` + args[0]
        console.error(...args)
        break
      default:
        args[0] = `D|    ` + args[0]
        console.log(...args)
        break
    }
  }
}
