import { createI18n } from "vue-i18n"

let locale = localStorage.getItem("locale")
if (!locale) {
  // Take the best matching locale from the browser environment
  locale = navigator?.language?.slice(0, 2)
}

const messages = Object.fromEntries(
  Object.entries(import.meta.globEager("../locales/*.json")).map(
    ([path, component]) => {
      const name = path.split("/").pop()?.slice(0, -5)
      return [name, component.default]
    }
  )
)

export const i18n = createI18n({
  locale,
  fallbackLocale: "en",
  messages,
})
