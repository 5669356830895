export const SocketEvent = {
  io: {
    server: {
      connect: "connection",
      disconnect: "disconnect",
      error: "error",
    },
    client: {
      connect: "connect",
      reconnect: "reconnect",
      disconnect: "disconnect",
      error: "error",
      ping: "ping",
    },
  },

  server: {
    config: "serverConfig",
    ping: "serverPing",
    pong: "serverPong",
  },

  room: {
    announce: "roomAnnounce",
    publish: "roomPublish",
    signal: "roomSignal",
    message: "roomMessage",
  },

  sfu: {
    getRouterRtpCapabilities: "sfuGetRouterRtpCapabilities",
    createProducerTransport: "sfuCreateProducerTransport",
    connectProducerTransport: "sfuConnectProducerTransport",
    produce: "sfuProduce",
    createConsumerTransport: "sfuCreateConsumerTransport",
    connectConsumerTransport: "sfuConnectConsumerTransport",
    consume: "sfuConsume",
    pause: "sfuPause",
    resume: "sfuResume",
  },

  apn: {},
}
