import {
  getDevices,
  getDisplayMedia,
  getUserMedia,
} from "./lib/usermedia-stream"
import { GlobalState } from "@/types"
import { DeviceInfo, MediaInfo } from "./types"
import { Logger } from "lib"
import { Emitter } from "lib"
import {
  MEDIA_AUDIO_DEFAULT_CONSTRAINTS,
  MEDIA_VIDEO_DEFAULT_CONSTRAINTS,
} from "./config"
import { VideoPlugin } from "./plugins/types"

const log = Logger("media")

const DESKTOP = "desktop"

const eventUpdateMedia = "updateMedia"
const eventUpdateMediaDevices = "updateMediaDevices"

interface MediaManagerMessages {
  [eventUpdateMedia]: (manager: MediaManager) => void
  [eventUpdateMediaDevices]: (manager: MediaManager) => void
}

class MediaManager extends Emitter<MediaManagerMessages> {
  devices: DeviceInfo[] = []
  deviceStream?: MediaStream
  deviceStreamMirrored: boolean = true
  deviceVideo?: string
  deviceAudio?: string
  muteVideo: boolean = false
  muteAudio: boolean = false
  plugins: VideoPlugin[]

  // To avoid import
  eventUpdateMedia = eventUpdateMedia
  eventUpdateMediaDevices = eventUpdateMediaDevices

  constructor(plugins: VideoPlugin[] = []) {
    super()
    this.plugins = plugins
    this.setup().then()
  }

  async setup() {
    this.updateDevices().then()
    // await this.updateStream()

    navigator?.mediaDevices?.addEventListener("devicechange", (ev) => {
      log("Update devices", ev)
      this.updateDevices().then()
    })
  }

  async start() {
    await this.updateStream()
  }

  stop(silently = false) {
    log("stop stream")
    this.deviceStream?.getTracks().forEach((t) => t.stop())
    this.deviceStream = undefined
    // this.deviceAudio = undefined
    // this.deviceVideo = undefined
    !silently && this.emit(eventUpdateMedia, this)
  }

  async updateStream() {
    log("updateStream")
    const showsDesktop = this.deviceVideo === DESKTOP

    let audio: MediaTrackConstraints = {
      ...MEDIA_AUDIO_DEFAULT_CONSTRAINTS,
    }

    let video: MediaTrackConstraints = {
      ...MEDIA_VIDEO_DEFAULT_CONSTRAINTS,
    }

    if (this.deviceAudio) {
      audio.deviceId = { exact: this.deviceAudio }
    }

    if (this.deviceVideo) {
      video.deviceId = { exact: this.deviceVideo }
    } else {
      video.facingMode = "user"
    }

    let constraints: MediaStreamConstraints = {
      audio,
      video,
    }

    if (this.muteAudio) {
      constraints.audio = false
    }

    if (this.muteVideo) {
      constraints.video = false
    }

    let stream: MediaStream
    let desktopStream: MediaStream
    let media: MediaInfo

    log("getUserMedia", JSON.stringify(constraints, null, 2))

    if (showsDesktop) {
      let { stream } = await getDisplayMedia({
        audio: false,
        video: MEDIA_VIDEO_DEFAULT_CONSTRAINTS,
      })
      if (stream) {
        desktopStream = stream
        delete constraints.video
      }
    }

    if (!this.muteAudio || !this.muteVideo) {
      media = await getUserMedia(constraints)

      if (media.error) {
        log.error(media.error)
        // media.message
      }

      stream = media.stream

      // log("Stream", stream, constraints)
      if (stream) {
        let success = true

        let audioTracks = stream.getAudioTracks()
        if (this.deviceAudio && audioTracks?.length <= 0) {
          this.deviceAudio = undefined
          success = false
        }

        if (desktopStream) {
          // setAudioTracks(desktopStream, audioTracks)
          stream = desktopStream
        }

        let videoTracks = stream.getVideoTracks()
        if (this.deviceVideo && videoTracks?.length <= 0) {
          this.deviceVideo = undefined
          success = false
        }

        this.deviceStream = stream

        // Reset to defaults
        if (!success) {
          await this.updateStream()
        }

        // if (this.plugins?.length > 0) {
        //   //  stream =
        //   await mediaStreamTransform(stream, this.plugins)
        // }

        // if (state.backgroundMode && !desktopStream) {
        //   blurLib = await import(
        //     /* webpackChunkName: 'blur' */ "./logic/background"
        //   )
        //   stream = await blurLib.startBlurTransform(stream)
        //   setAudioTracks(stream, audioTracks)
        // } else {
        //   if (blurLib) {
        //     log("stop blur")
        //     blurLib.stopBlurTransform()
        //   }
        //   blurLib = null
        // }
      } else {
        log.error("Media error:", media.error)
      }

      if (stream) {
        // Safari getDevices only works immediately after getUserMedia (bug)
        this.updateDevices().then()
      } else {
        log.error("Media error")
      }
    }

    await this.updateStreamInfo()
    // updateStream()

    this.emit(eventUpdateMedia, this)
  }

  // Identify active devices
  async updateStreamInfo() {
    let tracks = this.deviceStream?.getTracks() || []
    let activeDeviceLabels = tracks.map((t) => t.label)
    for (let d of this.devices) {
      d.active = activeDeviceLabels.includes(d.label)
    }
    this.emit(eventUpdateMediaDevices, this)
  }

  // Get list of available devices
  async updateDevices() {
    try {
      // this.state.info.supportedConstraints = navigator.mediaDevices.getSupportedConstraints()
      this.devices = <DeviceInfo[]>((await getDevices()) || [])
        .map((d) => {
          // log("found device", d.label)
          const kind = d?.kind?.toLowerCase()
          const label = d.label || "?"
          const title =
            d.label.replace(/\(.{9}\)/, "").trim() || "Default Device"
          if (["audioinput", "videoinput", "audiooutput"].includes(kind)) {
            const info: DeviceInfo = {
              // @ts-ignore
              kind,
              id: d?.deviceId,
              label,
              title,
              active: false,
            }
            return info
          }
          return undefined
        })
        .filter((d) => d != null)
      await this.updateStreamInfo()
    } catch (err) {
      log.error(err)
    }
  }

  async toggleMuteVideo() {
    this.stop()
    this.muteVideo = !this.muteVideo
    await this.updateStream()
  }

  async toggleMuteAudio() {
    this.stop()
    this.muteAudio = !this.muteAudio
    await this.updateStream()
  }

  async toggleMute() {
    this.stop()
    const mute = !(this.muteAudio || this.muteVideo)
    this.muteAudio = mute
    this.muteVideo = mute
    await this.updateStream()
  }

  async chooseAudioDevice(id: string) {
    if (this.deviceAudio !== id) {
      this.stop()
    }
    this.deviceAudio = id
    await this.updateStream()
  }

  async chooseVideoDevice(id: string) {
    if (this.deviceVideo !== id) {
      this.stop()
    }
    this.deviceVideo = id
    await this.updateStream()
  }

  async chooseDesktop() {
    await this.chooseVideoDevice(DESKTOP)
  }
}

export let mediaManager: MediaManager

export async function setupMedia(state: GlobalState) {
  // let facePlugin = new FacePlugin()
  // facePlugin.on("faces", (faces) => {
  //   state.info.faces = faces
  //   // log("faces", faces)
  // })

  mediaManager = new MediaManager([]) // facePlugin

  mediaManager.on(eventUpdateMedia, (mediaManager: MediaManager) => {
    let stream = mediaManager.deviceStream
    state.stream = stream
    state.streamMirrored = mediaManager.deviceVideo !== DESKTOP
  })

  mediaManager.on(eventUpdateMediaDevices, (mediaManager: MediaManager) => {
    state.devices = mediaManager.devices
  })
}
