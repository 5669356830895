import { decoding, encoding } from "lib0"
import { Logger } from "lib"

const log = Logger("peer-messages")

export enum PeerMessageIdentifier {
  notification = 1,
  sfu_announce = 2,
  status = 3,

  // above 100 only custom handled stuff
  barrier = 100,

  yjs_sync,
  yjs_queryAwareness,
  yjs_awareness,

  // message,
  // fileChunk,
}

// interface xx {
//   [PeerMessageIdentifier.notification](): void
//   [PeerMessageIdentifier.sfu_announce](x: number): void
// }

export function wrapMessage(
  id: PeerMessageIdentifier,
  message: any
): Uint8Array {
  const encoder = encoding.createEncoder()
  encoding.writeVarUint(encoder, id)
  encoding.writeAny(encoder, message)
  return encoding.toUint8Array(encoder)
}

export interface UnwrappedMessage {
  id: PeerMessageIdentifier
  message: any
}

export function unwrapMessage(buf: Uint8Array): UnwrappedMessage | null {
  try {
    const decoder = decoding.createDecoder(buf)
    const id = decoding.readVarUint(decoder)
    if (id < PeerMessageIdentifier.barrier) {
      const message = decoding.readAny(decoder)
      return {
        id,
        message,
      }
    }
  } catch (err) {
    log.warn("unwrapMessage", buf, err)
  }
  return null
}

export function messageIdentifier(
  buf: Uint8Array
): PeerMessageIdentifier | null {
  if (buf && buf.length > 0 && buf[0] < PeerMessageIdentifier.barrier) {
    return buf[0]
  }
  return null
}
