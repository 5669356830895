import { Logger, valueToBoolean, valueToString } from "lib"

// import Bowser from "bowser"
// const browserInfo = Bowser.parse(window.navigator.userAgent)
// const [major, minor, patch] = browserInfo.os.version.split(".")
// const isIOSWebKit =
//   browserInfo.os.name.toLocaleLowerCase() === "ios" &&
//   +major >= 14 &&
//   +minor >= 3
// isIOSWebKit ||
// const browser = Bowser.getParser(window.navigator.userAgent)
// const supported = browser.satisfies({
//   macos: {
//     safari: ">=11",
//   },
//   mobile: {
//     Safari: ">=14",
//     webkit: ">=605",
//   },
//   chromium: ">=55",
//   chrome: ">=55",
//   firefox: ">=60",
//   edge: ">=11",
// })

const isBrowserSupported = true

const log = Logger("config")

const env = import.meta.env

export const DEVELOPMENT = env.MODE !== "production"
export const PRODUCTION = !DEVELOPMENT
export const DEBUG = DEVELOPMENT

export const config = {
  env,
  app: {
    name: env.VITE_APP_NAME,
    version: env.VITE_APP_VERSION,
    release: env.VITE_APP_RELEASE,
    // author_name: env.VITE_APP_AUTHOR_NAME,
    // author_email: env.VITE_APP_AUTHOR_EMAIL,
  },
  browser: {
    supported: isBrowserSupported,
  },
  signal: {
    server: valueToString(env.VITE_SIGNAL_URL, "wss://on.viidoo.chat:4444"),
  },
  webrtc: {
    ice: {
      reconnectTimer: 3000,

      trickle: true,
      iceTransportPolicy: "all",
      iceServers: [
        {
          urls: "stun:on.viidoo.chat", // :5349",
        },
        {
          urls: "turn:on.viidoo.chat", // :5349",
          username: "viidoo",
          credential: "000000",
        },
      ],

      // Allow the peer to receive video, even if it's not sending stream:
      // https://github.com/feross/simple-peer/issues/95
      // http://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/createOffer
      offerConstraints: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      },
    },
  },
  defaultRoomName: "boring-ground-25",
  media: {
    supportRecognition: valueToBoolean(
      env.VITE_MEDIA_SUPPORT_RECOGNITION,
      false
    ),
    videoConstrains: {
      low: {
        width: { ideal: 320 },
        height: { ideal: 240 } /*, frameRate: { exact: 25}*/,
      },
      medium: {
        width: { ideal: 640 },
        height: { ideal: 480 } /*, frameRate: { exact: 25}*/,
      },
      high: {
        width: { ideal: 1280 },
        height: { ideal: 720 } /*, frameRate: { exact: 25}*/,
      },
      veryhigh: {
        width: { ideal: 1920 },
        height: { ideal: 1080 } /*, frameRate: { exact: 25}*/,
      },
      // ultra: {
      //   width: { ideal: 3840 },
      //   height: { ideal: 0}
      // },
    },
  },
  security: {
    enforcePassword: valueToBoolean(env.VITE_ENFORCE_PASSWORD, false),
    defaultPassword: "000000",
  },
  sentry: {
    active: valueToBoolean(env.VITE_SENTRY, true),
    dsn: valueToString(env.VITE_SENTRY_DSN),
  },
  realtime: {
    enabled: 1,
  },
  sfu: {
    enabled: valueToBoolean(env.VITE_SFU, false),
    simulcast: valueToBoolean(env.VITE_SFU_SIMULCAST, false),
    simulcastShare: valueToBoolean(env.VITE_SFU_SIMULCAST_SHARE, false), // Desktop sharing
    supportVP9: false,
    encodings: [
      { scaleResolutionDownBy: 4 },
      { scaleResolutionDownBy: 2 },
      { scaleResolutionDownBy: 1 },
      // { scaleResolutionDownBy: 4, maxBitRate: 100000 },
      // { scaleResolutionDownBy: 1, maxBitRate: 1200000 },
      // { scaleResolutionDownBy: 4, maxBitrate: 500000 },
      // { scaleResolutionDownBy: 2, maxBitrate: 1000000 },
      // { scaleResolutionDownBy: 1, maxBitrate: 5000000 },
    ],
    encodingsVP9: [{ scalabilityMode: "S3T3_KEY" }],
    encodingsVP9Share: [{ scalabilityMode: "S3T3", dtx: true }],
  },
}

// log.info("config", JSON.stringify(config, null, 2))

if (
  config.sfu.enabled &&
  config.signal.server.includes("localhost") &&
  // location.hostname === "localhost" &&
  navigator.userAgent.includes("Firefox/")
) {
  log.error(
    "SFU is not supported by Firefox on localhost. Use Chrome for testing!"
  )
}
