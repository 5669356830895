// See https://github.com/yjs/y-webrtc/blob/master/src/y-webrtc.js

import { Disposable, Emitter, Logger, LogLevel } from "lib"
import { decoding, encoding } from "lib0"
import * as awarenessProtocol from "y-protocols/awareness.js"
import * as syncProtocol from "y-protocols/sync.js"
import * as Y from "yjs"
import { PeerMessageIdentifier } from "./peer-messages"
import { SimpleWebRTC } from "./simple-webrtc"
import { SimpleWebRTCPeer } from "./simple-webrtc-peer"

const log = Logger("sync-provider")
log.level = LogLevel.info

export class YjsRealtimeSync extends Emitter implements Disposable {
  doc: Y.Doc
  awareness: awarenessProtocol.Awareness
  synced: boolean = false
  peerId: string
  peerManager: SimpleWebRTC

  // _docUpdateHandler: Function
  // _awarenessUpdateHandler: Function

  _docUpdateHandler = async (update: Uint8Array, _origin: any) => {
    const encoder = encoding.createEncoder()
    encoding.writeVarUint(encoder, PeerMessageIdentifier.yjs_sync)
    syncProtocol.writeUpdate(encoder, update)
    let data = encoding.toUint8Array(encoder)
    await this.peerManager.broadcast(data)
  }

  _awarenessUpdateHandler = async (
    { added, updated, removed }: any,
    _origin: any
  ) => {
    log.debug("_awarenessUpdateHandler", added, updated, removed)
    const changedClients = added.concat(updated).concat(removed)
    const encoderAwareness = encoding.createEncoder()
    encoding.writeVarUint(encoderAwareness, PeerMessageIdentifier.yjs_awareness)
    encoding.writeVarUint8Array(
      encoderAwareness,
      awarenessProtocol.encodeAwarenessUpdate(this.awareness, changedClients)
    )
    let data = encoding.toUint8Array(encoderAwareness)
    await this.peerManager.broadcast(data)
  }

  constructor(webrtc: SimpleWebRTC, doc: Y.Doc) {
    super()

    log.debug("WebrtcProvider.constructor")
    this.peerManager = webrtc
    this.doc = doc
    this.awareness = new awarenessProtocol.Awareness(doc)
    this.peerId = webrtc.uuid

    // Listens to Yjs updates and sends them to remote peers
    // this._docUpdateHandler = async (update: Uint8Array, _origin: any) => {
    //   const encoder = encoding.createEncoder()
    //   encoding.writeVarUint(encoder, PeerMessageIdentifier.yjs_sync)
    //   syncProtocol.writeUpdate(encoder, update)
    //   let data = encoding.toUint8Array(encoder)
    //   await this.peerManager.broadcast(data)
    // }

    // Listens to Awareness updates and sends them to remote peers
    // this._awarenessUpdateHandler = async (
    //   { added, updated, removed }: any,
    //   _origin: any
    // ) => {
    //   log.debug("_awarenessUpdateHandler", added, updated, removed)
    //   const changedClients = added.concat(updated).concat(removed)
    //   const encoderAwareness = encoding.createEncoder()
    //   encoding.writeVarUint(
    //     encoderAwareness,
    //     PeerMessageIdentifier.yjs_awareness
    //   )
    //   encoding.writeVarUint8Array(
    //     encoderAwareness,
    //     awarenessProtocol.encodeAwarenessUpdate(this.awareness, changedClients)
    //   )
    //   let data = encoding.toUint8Array(encoderAwareness)
    //   await this.peerManager.broadcast(data)
    // }

    this.doc.on("update", this._docUpdateHandler)
    this.awareness.on("update", this._awarenessUpdateHandler)

    window.addEventListener("beforeunload", () => {
      log.debug("beforeunload")
      awarenessProtocol.removeAwarenessStates(
        this.awareness,
        [doc.clientID],
        "window unload"
      )
      // rooms.forEach((room) => {
      //   room.disconnect()
      // })
    })

    // const encoderSync = encoding.createEncoder()
    // encoding.writeVarUint(encoderSync, PacketIdentifier.sync)
    // syncProtocol.writeSyncStep1(encoderSync, this.doc)

    // const encoderState = encoding.createEncoder()
    // encoding.writeVarUint(encoderState, PacketIdentifier.sync)
    // syncProtocol.writeSyncStep2(encoderState, this.doc)

    // const encoderAwarenessQuery = encoding.createEncoder()
    // encoding.writeVarUint(
    //   encoderAwarenessQuery,
    //   PacketIdentifier.queryAwareness
    // )

    // const encoderAwarenessState = encoding.createEncoder()
    // encoding.writeVarUint(encoderAwarenessState, PacketIdentifier.awareness)
    // encoding.writeVarUint8Array(
    //   encoderAwarenessState,
    //   awarenessProtocol.encodeAwarenessUpdate(this.awareness, [
    //     this.doc.clientID,
    //   ])
    // )

    this.peerManager.forEachPeer((peer) => {
      if (peer.active) {
        this.peerConnected(peer)
      }
    })

    this.peerManager.on("connect", ({ peer }) => {
      this.peerConnected(peer)
    })

    this.peerManager.on("data", ({ peer, data }) => {
      this.peerData(peer, data)
    })
  }

  readMessage(
    data: Uint8Array,
    syncedCallback: () => void
  ): encoding.Encoder | null {
    const decoder = decoding.createDecoder(data)
    const encoder = encoding.createEncoder()
    const messageType = decoding.readVarUint(decoder)
    if (this === undefined) {
      return null
    }
    const awareness = this.awareness
    const doc = this.doc
    let sendReply = false

    log.debug("readMessage", messageType)

    switch (messageType) {
      case PeerMessageIdentifier.notification: {
        break
      }
      case PeerMessageIdentifier.sfu_announce: {
        break
      }
      case PeerMessageIdentifier.yjs_sync: {
        encoding.writeVarUint(encoder, PeerMessageIdentifier.yjs_sync)
        const syncMessageType = syncProtocol.readSyncMessage(
          decoder,
          encoder,
          doc,
          this
        )
        if (
          syncMessageType === syncProtocol.messageYjsSyncStep2 &&
          !this.synced
        ) {
          syncedCallback()
        }
        if (syncMessageType === syncProtocol.messageYjsSyncStep1) {
          sendReply = true
        }
        break
      }
      case PeerMessageIdentifier.yjs_queryAwareness:
        encoding.writeVarUint(encoder, PeerMessageIdentifier.yjs_awareness)
        encoding.writeVarUint8Array(
          encoder,
          awarenessProtocol.encodeAwarenessUpdate(
            awareness,
            Array.from(awareness.getStates().keys())
          )
        )
        sendReply = true
        break
      case PeerMessageIdentifier.yjs_awareness:
        awarenessProtocol.applyAwarenessUpdate(
          awareness,
          decoding.readVarUint8Array(decoder),
          this
        )
        break
      default:
        log.warn("Unable to compute message", messageType)
        return encoder
    }
    if (!sendReply) {
      // nothing has been written, no answer created
      return null
    }
    return encoder
  }

  peerConnected(peerChannel: SimpleWebRTCPeer) {
    const encoder = encoding.createEncoder()
    encoding.writeVarUint(encoder, PeerMessageIdentifier.yjs_sync)
    syncProtocol.writeSyncStep1(encoder, this.doc)
    peerChannel.send(encoding.toUint8Array(encoder))
    const awarenessStates = this.awareness.getStates()
    if (awarenessStates.size > 0) {
      const encoder = encoding.createEncoder()
      encoding.writeVarUint(encoder, PeerMessageIdentifier.yjs_awareness)
      encoding.writeVarUint8Array(
        encoder,
        awarenessProtocol.encodeAwarenessUpdate(
          this.awareness,
          Array.from(awarenessStates.keys())
        )
      )
      peerChannel.send(encoding.toUint8Array(encoder))
    }
  }

  peerData(peerChannel: SimpleWebRTCPeer, data: any) {
    try {
      const answer = this.readMessage(data, () => {
        peerChannel.synced = true

        let synced = true
        this.peerManager.forEachPeer((peer) => {
          if (!peer.synced) synced = false
        })
        if ((!synced && this.synced) || (synced && !this.synced)) {
          this.synced = synced
          // room.provider.emit("synced", [{ synced }])
        }
      })

      if (answer !== null) {
        peerChannel.send(encoding.toUint8Array(answer))
      }
    } catch (error) {
      log.error(error)
    }
  }

  async cleanup() {
    log.debug("WebrtcProvider.cleanup")

    // signal through all available signaling connections
    awarenessProtocol.removeAwarenessStates(
      this.awareness,
      [this.doc.clientID],
      "disconnect"
    )

    this.doc.off("update", this._docUpdateHandler)
    this.awareness.off("update", this._awarenessUpdateHandler)
    // this.webrtcConns.forEach((conn) => conn.destroy())
  }
}
