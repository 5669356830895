// The state stores global reactive values, that are accessible in all views
// TODO: Typescript support

import { Logger } from "lib"
import { ViidooRoomManager } from "@/sync"
import { useLocalStorage } from "@vueuse/core"
import { computed, reactive, watch } from "vue"
import * as Y from "yjs"
import { config } from "./config"
import { generateName } from "./lib/names"
import { UUID } from "./lib/uuid"
import { setupMedia } from "./media"
import { generatePIN } from "./sync/lib/crypto"
import { ChatMessage, GlobalState, Stage, User } from "./types"

const log = Logger("state")

const defaultState: GlobalState = {
  room: "",
  error: "",
  connection: "none",
  secret: "",
  info: {},
  devices: [],
  stream: undefined,
  streamMirrored: true,
  webrtc: undefined,
  // showDocument: false,
  showSidebar: "",
  chat: {},
  users: {},
  stage: {
    showDocument: false,
  },
}

export let state = reactive<GlobalState>({
  ...defaultState,
})

const secret = config.security.enforcePassword
  ? generatePIN()
  : config.security.defaultPassword
// sessionStorage.get(`secret-${room}`)

export function enterRoom(room: string) {
  Object.assign(state, {
    ...defaultState,
    room,
  })
}

export function leaveRoom() {
  Object.assign(state, {
    ...defaultState,
  })
}

export const mirrorVideo = useLocalStorage("mirrorVideo", true)
export const fillVideo = useLocalStorage("fillVideo", true)
export const userName = useLocalStorage("user.name", generateName())
export const userId = useLocalStorage("user.id", UUID())

export const chatReadCount = useLocalStorage<number>("chat.readCount", 0)
export const chatUnreadCount = computed(() =>
  state.showSidebar === "chat"
    ? 0
    : Math.max(0, Object.keys(state.chat || {}).length - +chatReadCount.value)
)

watch(userName, (value) => {
  log("update username", value)
  sync.users.set(userId.value, {
    id: userId.value,
    name: userName.value,
  })
})

// watch(
//   () => state.stage,
//   (value) => {
//     log("update stage", value)
//     Object.entries(value).forEach(([key, value]) => {
//       log("update stage", key, value)
//       sync.stage.set(key, value as any)
//     })
//   }
// )

export const peers = computed(() => Object.values(state?.webrtc?.peers || {}))

// Realtime (Yjs)

class RoomManager extends ViidooRoomManager {
  whiteboard: Y.Array<any>
  chat: Y.Map<ChatMessage>
  users: Y.Map<User>
  stage: Y.Map<Stage>
}

export let sync = new RoomManager()

sync.on("ready", () => {
  sync.whiteboard = sync.doc.getArray("whiteboard")

  sync.users = sync.doc.getMap("users")
  sync.users.set(userId.value, {
    id: userId.value,
    name: userName.value,
    userAgent: navigator.userAgent,
    languages: Array.from(navigator.languages),
  })

  sync.chat = sync.doc.getMap("chat")
  sync.chat.observe(() => {
    state.chat = sync.chat.toJSON()
  })

  sync.stage = sync.doc.getMap("stage")
  sync.stage.observe(() => {
    state.stage = sync.stage.toJSON()
  })
})

sync.on("error", (msg) => {
  log.error("yjs error", msg)
  state.error = msg
})

// Devices

async function start() {
  await setupMedia(state)
}

start().then()
