// See https://github.com/yjs/y-webrtc/blob/master/src/crypto.js

import { decoding, encoding, error, promise, string } from "lib0"

export function generatePIN() {
  return Math.floor(Math.random() * 1000000)
    .toFixed(0)
    .padStart(6, "0")
}

export const deriveKey = async (
  secret: string,
  roomName: string
): Promise<CryptoKey> => {
  const secretBuffer = string.encodeUtf8(secret).buffer
  const salt = string.encodeUtf8(roomName).buffer
  const keyMaterial = await crypto.subtle.importKey(
    "raw",
    secretBuffer,
    "PBKDF2",
    false,
    ["deriveKey"]
  )
  return await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt,
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"]
  )
}

export const encrypt = async (
  data: Uint8Array,
  key: CryptoKey | null
): Promise<Uint8Array> => {
  if (!key) {
    // @ts-ignore
    return promise.resolve(data)
  }
  const iv = crypto.getRandomValues(new Uint8Array(12))
  const cipher = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv,
    },
    key,
    data
  )
  const encryptedDataEncoder = encoding.createEncoder()
  encoding.writeVarString(encryptedDataEncoder, "AES-GCM")
  encoding.writeVarUint8Array(encryptedDataEncoder, iv)
  encoding.writeVarUint8Array(encryptedDataEncoder, new Uint8Array(cipher))
  return encoding.toUint8Array(encryptedDataEncoder)
}

export const encryptJson = (
  data: object,
  key: CryptoKey | null
): Promise<Uint8Array> => {
  const dataEncoder = encoding.createEncoder()
  encoding.writeAny(dataEncoder, data)
  return encrypt(encoding.toUint8Array(dataEncoder), key)
}

export const decrypt = async (
  data: Uint8Array,
  key: CryptoKey | null
): Promise<Uint8Array> => {
  if (!key) {
    // @ts-ignore
    return promise.resolve(data)
  }
  const dataDecoder = decoding.createDecoder(data)
  const algorithm = decoding.readVarString(dataDecoder)
  if (algorithm !== "AES-GCM") {
    promise.reject(error.create("Unknown encryption algorithm"))
  }
  const iv = decoding.readVarUint8Array(dataDecoder)
  const cipher = decoding.readVarUint8Array(dataDecoder)
  const data_1 = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv,
    },
    key,
    cipher
  )
  return new Uint8Array(data_1)
}

export const decryptJson = (
  data: Uint8Array,
  key: CryptoKey | null
): Promise<object> =>
  decrypt(data, key).then((decryptedValue) =>
    decoding.readAny(decoding.createDecoder(new Uint8Array(decryptedValue)))
  )
