import { config } from "@/config"
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"
import { LogHandler, LogLevel, LogMessage, renderMessages } from "lib"

export function setupSentry() {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],
    release: String(config.app.release),
    tracesSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb, hint) {
      return breadcrumb.category === "console" ? null : breadcrumb
    },
  })

  window.addEventListener("error", (event) => {
    Sentry.captureException(event)
  })
  window.addEventListener("unhandledrejection", (event) => {
    Sentry.captureException(event)
  })

  console.info("Sentry error tracking active for " + config.app.release)
}

export function sentryVueErrorHandler() {
  return (err: any, vm: any, info: any) => {
    Sentry.captureException(err)
  }
}

export function LoggerSentryHandler(
  level: LogLevel = LogLevel.debug
): LogHandler {
  return (msg: LogMessage) => {
    if (msg.level < level) return
    const name = msg.name || ""
    const message = `[${name}] ` + renderMessages(msg.messages)
    // const timestamp = new Date().getTime()
    const category = "log"
    switch (msg.level) {
      case LogLevel.error:
        Sentry.addBreadcrumb({
          // timestamp,
          category,
          message,
          level: Sentry.Severity.Error,
        })
        Sentry.withScope(() => {
          Sentry.captureMessage(message)
        })
      case LogLevel.warn:
        Sentry.addBreadcrumb({
          category,
          message,
          level: Sentry.Severity.Warning,
        })
      case LogLevel.info:
        Sentry.addBreadcrumb({
          category,
          message,
          level: Sentry.Severity.Info,
        })
      case LogLevel.debug:
        Sentry.addBreadcrumb({
          category,
          message,
          level: Sentry.Severity.Debug,
        })
    }
  }
}
