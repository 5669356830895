// @ts-ignore Workaround
window.global = window

import { Logger } from "@/lib/log"
import { createApp } from "vue"
// import "./registerServiceWorker"
import { i18n } from "./i18n"
import { sentryVueErrorHandler } from "./lib/sentry"
import { router } from "./router"
import appComponent from "./views/app.vue"

import matomo from "vue-matomo"
import { DEBUG } from "./config"

const log = Logger("main")

log.info("Start server")

const app = createApp(appComponent)
app.config.errorHandler = sentryVueErrorHandler()

app.use(router)

// https://github.com/AmazingDreams/vue-matomo#readme
if (!DEBUG) {
  app.use(matomo, {
    host: "https://stats.holtwick.de/matomo",
    siteId: 10,

    // Enables automatically registering pageviews on the router
    router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: true,

    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    disableCookies: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: true,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 5,

    // Overrides the tracker endpoint entirely
    // Default: undefined
    trackerUrl: undefined,

    // Overrides the tracker script path entirely
    // Default: undefined
    trackerScriptUrl: undefined,

    // Whether or not to log debug information
    // Default: false
    debug: true,

    // User ID
    // Default: undefined
    userId: "some-user-id",

    preInitActions: [
      ["setCustomVariable", "1", "VisitorType", "Member"],
      ["appendToTrackingUrl", "new_visit=1"],
    ],
  })
}

app.use(i18n)
app.mount("#app")

log.info("Server started")
