// https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSupportedConstraints
// https://mdn.mozillademos.org/en-US/docs/Web/API/Media_Streams_API/Constraints%24samples/Example_Constraint_exerciser?revision=1588638

export const MEDIA_VIDEO_DEFAULT_CONSTRAINTS: MediaTrackConstraints = {
  // frameRate: {
  //   min: 1,
  //   ideal: 15,
  // },
}

export const MEDIA_AUDIO_DEFAULT_CONSTRAINTS: MediaTrackConstraints = {
  echoCancellation: true,
  noiseSuppression: true,
  autoGainControl: true,
}

export const MEDIA_DESKTOP_DEFAULT_CONSTRAINTS: MediaTrackConstraints = {
  // @ts-ignore
  displaySurface: "monitor",
  logicalSurface: true,
  // @ts-ignore
  cursor: true,
  width: { max: 1920 },
  height: { max: 1080 },
  frameRate: { max: 30 },
}
